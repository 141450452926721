.landingPage {
    height: 100vh;
    background: #2B3C51;
    display: flex;
    align-items: center;
    justify-content: center;

    .img1 {
        margin-bottom: 3rem;
        cursor: pointer;
    }

    .img2 {
        display: none;
        cursor: pointer;
    }

    h1 {
        font-family: Baloo Tammudu;
        font-size: 35px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        position: absolute;
        bottom: 2rem;
        color: #fff
    }

}


@media only screen and (max-width: 900px) {
    .landingPage {
        padding: 0 5%;

        h1 {
            font-family: Baloo Tammudu;
            font-size: 28px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .img1 {
            display: none;

        }

        .img2 {
            display: block;
            display: block;
            width: 195px;
            margin-bottom: 7rem;
        }
    }
}