@import url(https://fonts.googleapis.com/css2?family=Baloo+Tammudu:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sad
{
  position: relative;
}
.overlay{top:0;left:0;width:100%;height:100%;position:absolute;}

.landingPage{height:100vh;background:#2b3c51;display:flex;align-items:center;justify-content:center}.landingPage .img1{margin-bottom:3rem;cursor:pointer}.landingPage .img2{display:none;cursor:pointer}.landingPage h1{font-family:Baloo Tammudu;font-size:35px;font-weight:400;line-height:25px;text-align:center;position:absolute;bottom:2rem;color:#fff}@media only screen and (max-width: 900px){.landingPage{padding:0 5%}.landingPage h1{font-family:Baloo Tammudu;font-size:28px;font-weight:400;line-height:40px;text-align:center;display:flex;align-items:center;justify-content:center}.landingPage .img1{display:none}.landingPage .img2{display:block;display:block;width:195px;margin-bottom:7rem}}
